import { Component, componentService } from '@contorion/core';

componentService.register('.js-searchbar', (element: Element) => {
    import (
        /* webpackChunkName: "search" */
        './components/search.component')
        .then(({ SearchComponent }) => {
            <Component> SearchComponent.loadByElement(element);
        });
});

componentService.register('.js-search-feedback', (element: Element) => {
    import (
        /* webpackChunkName: "search-feedback" */
        './components/search-feedback.component')
        .then(({ SearchFeedbackComponent }) => {
            <Component> SearchFeedbackComponent.loadByElement(element);
        });
});

componentService.register('.js-search-AB-test', (element: Element) => {
    import (
        /* webpackChunkName: "search-AB-test" */
        './components/search-AB-test.component')
        .then(({ SearchABTestComponent }) => {
            <Component> SearchABTestComponent.loadByElement(element);
        });
});
